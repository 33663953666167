body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.06); 
  border-radius:10px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #23506c; 
}

@font-face {
  font-family: 'Gaoel';
  src: url('./components/assets/fonts/Gaoel.ttf');
  font-weight: normal;
  font-style: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.detrator-logo{
  font-family: 'Gaoel';
  text-transform: uppercase;
  font-weight: normal;
  font-size: 25px;
  color: #fff;
  letter-spacing: 5px;
}

@media (max-width:600px) {
  .detrator-logo{
    font-size: 20px;
  }
}

.QuotsText{
  font-family: 'Zen Antique Soft', serif;
}