
.header-menu-items{
    /* margin-left: 30px; */
    cursor: pointer;
    align-items: end;
    justify-content: end;
}

.header-menu-items:last-child{
    margin-right: 30px;
}
.header-menu-items:hover{
    color: #00bcd4;
}
